import { Loader } from "google-maps";
import inview from "./utils/inview";
import eventBus from "./utils/eventBus";

function GMap(el) {
  const { observe, disconnect } = inview;
  const lat = +(el.dataset.lat ?? 49.212608);
  const lon = +(el.dataset.lon ?? -2.1325);
  let mapHasInit = false;

  const options = {
    /* todo */
  };

  if (!window.maploader) {
    window.maploader = new Loader(
      "AIzaSyApBTQIeTtkeohbbgYx0Bmm_8Bz6FpGYkY",
      options
    );
  }
  // const loader = new Loader("AIzaSyCAbT8rSNKLA327EWb2fFgb5p8LGF_pkp0", options);

  function initMap() {
    console.log("init map");
    window.maploader.load().then(function (google) {
      el.style.height = "600px";
      eventBus.emit("scrollTriggerRefresh");
      const map = new google.maps.Map(el, {
        styles: [
          {
            featureType: "all",
            elementType: "labels.text",
            stylers: [
              {
                color: "#878787",
              },
            ],
          },
          {
            featureType: "all",
            elementType: "labels.text.stroke",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "landscape",
            elementType: "all",
            stylers: [
              {
                color: "#f9f5ed",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "all",
            stylers: [
              {
                color: "#f5f5f5",
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#c9c9c9",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "all",
            stylers: [
              {
                color: "#aee0f4",
              },
            ],
          },
        ],
        center: { lat: lat, lng: lon },
        zoom: 8,
        mapTypeControl: false,
      });

      new google.maps.Marker({
        position: { lat: lat, lng: lon },
        map,
      });
    });
  }

  observe(el, (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && !mapHasInit) {
        initMap();
        mapHasInit = true;
        // disconnect();
      }
    });
  });

  return () => {
    console.log("destroy map");
    disconnect();
  };
}

export default GMap;
