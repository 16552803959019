import { gsap } from "gsap";
import h from "hyperscript";

export default async function AjaxPosts(el) {
  const listing = el.querySelector("[data-listing]");
  const filter = el.querySelector("[data-filter]");
  const pagination = el.querySelector("[data-pagination]");
  const prev = el.querySelector("[data-pagination] [data-prev]");
  const next = el.querySelector("[data-pagination] [data-next]");
  const section = el.dataset.section;
  let pagination_meta = {};

  filter.addEventListener("change", updateListing);

  prev.addEventListener("click", () => {
    if (pagination_meta?.current_page > 1) {
      updateListing(pagination_meta.current_page - 1);
    }
  });

  next.addEventListener("click", () =>
    updateListing(pagination_meta.current_page + 1)
  );

  async function updateListing(page = 1) {
    gsap.to(listing, { opacity: 0, duration: 0.2 });
    const idParam = getCatIds(filter)
      .map((id) => `ids[]=${id}`)
      .join("&");
    const url = `${window.siteUrl}api/${section}.json?page=${page}&${idParam}`;
    const posts = await fetch(url).then((r) => r.json());
    listing.replaceChildren(...posts.data.map(getCardHTML));
    gsap.to(listing, { opacity: 1, duration: 0.2 });
    pagination_meta = posts.meta.pagination;
    updatePagination(pagination, posts.meta.pagination);
  }

  updateListing();

  function getCatIds(filter) {
    return [...filter.querySelectorAll(":checked")].map((el) => el.value);
  }

  function getCardHTML(post) {
    console.log(post);

    return h(
      "a",
      {
        href: post.url,
        className:
          "px-3 mb-6 w-full sm:w-1/2 lg:w-1/3 2xl:w-1/3 flex transition-all transform hover:scale-105",
      },
      h(
        "div",
        {
          className: "bg-[#E8E5E5] p-4 rounded-xl relative w-full flex-1",
        },
        h("div", {
          style: { "background-color": post.newsCategory?.[0]?.color ?? "" },
          className:
            "w-4 h-4 bg-green rounded-full absolute left-0 top-0 border-[#E8E5E5] border-[10px] box-content",
        }),

        h("img", {
          src: post.image,
          className: "w-full rounded-xl mb-1",
          alt: post.title,
        }),

        h(
          "p",
          {
            className: `text-white px-5 absolute right-0 top-0 rounded-tr-xl bg-green rounded-bl-md w-max text-right text-[15px] py-2 font-bold ${
              !post.topProject && "hidden"
            }`,
          },
          "Top"
        ),

        h(
          "div",
          {
            className:
              "flex text-[#36748D] font-bold justify-between text-[16px]",
          },
          h("p", post.newsCategory?.[0]?.title ?? ""),
          h("time", section == "news" ? post.date : "")
        ),
        h("h4", { className: "font-bold" }, post.title + ""),
        h("div", { innerHTML: post.abstract })
      )
    );
  }

  function updatePagination(el, { current_page, total_pages }) {
    el.classList.remove("hidden");
    const linksEl = el.querySelector("[data-links]");

    const page = (i) => i + 1;
    const linksEls = Array(total_pages)
      .fill(0)
      .map((_, i) =>
        h(
          "button",
          {
            className: `w-12 md:flex justify-center items-center cursor-pointer leading-5 transition-all rounded-full ${
              page(i) == current_page
                ? "bg-teal-600 text-white flex "
                : " hidden "
            }`,
            onclick: () => updateListing(page(i)),
          },
          page(i)
        )
      );
    linksEl.replaceChildren(...linksEls);
  }
}
