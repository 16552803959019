import { gsap } from "gsap/all";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// gsap.registerPlugin(ScrollTrigger);

import "core-js/stable";
import "regenerator-runtime/runtime";
import barba from "@barba/core";
import Slider from "./js/Slider";
import GMap from "./js/GMap";
import Video from "./js/Video";
import Form from "./js/Form";
import Header from "./js/Header";
import Menu from "./js/Menu";
import BackgroundVideo from "./js/BackgroundVideo";
import fitty from "fitty";
import VideoPopup from "./js/VideoPopup";
import AjaxPosts from "./js/AjaxPosts";
import Animations from "./js/Animations";
import Gallery from "./js/Gallery";
import eventBus from "./js/utils/eventBus";

import lazySizes from "lazysizes";

import "./app.scss";

barba.init({
  debug: false,
  logLevel: "error",
  timeout: 3000,
  transitions: [
    {
      name: "opacity-transition",
      leave(data) {
        return gsap.to(data.current.container, {
          opacity: 0,
          duration: 0.2,
        });
      },
      enter(data) {
        return gsap.from(data.next.container, {
          opacity: 0,
          duration: 0.2,
        });
      },
    },
  ],
});

barba.hooks.beforeEnter((data) => {});

barba.hooks.after((data) => {
  document.body.classList.remove("overflow-hidden");
  window.scroll(0, 0);
  eventBus.emit("afterPageChange");
  runActions();
});

runActions();

function action(attr, func) {
  if (attr.indexOf(".") !== -1 || attr.indexOf("#") !== -1) {
    document.querySelectorAll(attr).forEach((el) => func(el));
  } else {
    document
      .querySelectorAll(`[data-action="${attr}"]`)
      .forEach((el) => func(el));
  }
}

function runActions() {
  console.log("runActions");

  action("GMap", GMap);
  action("Video", Video);
  action("Form", Form);
  action("Slider", Slider);
  action("#header", Header);
  action("BackgroundVideo", BackgroundVideo);
  action(".watch-video", VideoPopup);
  action("AjaxPosts", AjaxPosts);
  action("Gallery", Gallery);

  Menu(document.querySelectorAll(".menu-btn"));

  fitty(".fitty");

  Animations();

  const modelBtn = document.querySelectorAll(".model-btn");
  const modelClose = document.querySelectorAll(".model-close");

  if (modelBtn) {
    modelBtn.forEach((btn) =>
      btn.addEventListener("click", (e) => {
        e.preventDefault();
        // menu?.classList?.toggle("hidden");
        document.body.classList.add("overflow-hidden");
        const modelId = btn.dataset.target;
        const model = document.getElementById(modelId);
        model.classList.toggle("hidden");
      })
    );
  }

  if (modelClose) {
    modelClose.forEach((btn) =>
      btn.addEventListener("click", (e) => {
        console.log(e.target.closest(".model"));
        e.target.closest(".model").classList.add("hidden");
        document.body.classList.remove("overflow-hidden");
      })
    );
  }
}
