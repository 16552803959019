export default function Video(el) {
  const poster = el.querySelector("a");
  const iframe = el.querySelector("iframe");

  poster.addEventListener("click", (e) => {
    e.preventDefault();
    poster.remove();
    iframe.src = iframe.dataset.src;
  });
}
