// import events from "./utils/events";
import { gsap } from "gsap";

export default function Form(el) {
  const siteUrl = window?.siteUrl ?? "/";
  // const { addEvent, removeEvents } = events;
  const error = el.querySelector("[data-errors]");
  const submit = el.querySelector('[type="submit"]');
  const success = document.querySelector(el.dataset?.success);

  el.addEventListener("submit", (e) => {
    e.preventDefault();
    console.log("usmibt", el.action.value);
    submit.setAttribute("disabled", true);
    submit.innerText = "Sending...";

    fetch(siteUrl, {
      method: "post",
      body: new FormData(el),
      headers: {
        Accept: "application/json",
      },
    })
      .then((r) => r.json())
      .then((data) => {
        console.log(data);
        if (data?.success) showSuccess();
        if (data?.errors) showErrors(data.errors);
      })
      .catch((e) => {
        error.innerHTML = "⚠️ Something went wrong please try again.";
        submit.removeAttribute("disabled");
        submit.innerText = "Submit";
      });
  });
  // addEvent("submit", el, (e) => {
  // });

  function showSuccess() {
    el.classList.add("hidden");
    success.classList.remove("hidden");
    gsap.fromTo(
      success,
      { opacity: 0, scale: 0.5 },
      { opacity: 1, scale: 1, duration: 0.2 }
    );
  }

  function showErrors(errors) {
    console.log(errors);
    let msg = "";
    for (const error in errors) {
      msg += `⚠️ ${errors[error]}<br/>`;
    }
    error.innerHTML = msg;

    error.classList.remove("hidden");
    submit.removeAttribute("disabled");
    submit.innerText = "Submit";
  }

  return () => {
    // removeEvents(el);
  };
}
