import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import eventBus from "./utils/eventBus";

gsap.registerPlugin(ScrollTrigger);

export default function Animations() {
  const debug = false;
  let tls = [];

  document.querySelectorAll(".slide-anim").forEach((ps) => {
    const img = ps.querySelector(".sa-image");
    const cov = ps.querySelector(".sa-cover");
    const inner = ps.querySelector(".sa-inner");

    const tl = gsap.timeline({
      scrollTrigger: {
        markers: debug,
        trigger: ps,
        start: "top bottom-=20%", // trigger viewport
        end: "bottom top", // trigger viewport
        toggleActions: "restart reverse play reverse",
      },
    });
    if (window.innerWidth > 768) {
      tl.fromTo(
        img,
        { x: "100%", opacity: 0 },
        { x: "0%", opacity: 1, duration: 1 },
        "frame"
      );
    }
    tl.fromTo(
      cov,
      { width: "100%" },
      { width: "33%", duration: 1, delay: 0.25 },
      "frame"
    ).fromTo(
      inner,
      { opacity: 0 },
      { opacity: 1, duration: 1, delay: 0.25 },
      "frame"
    );

    tls.push(tl);
  });

  document.querySelectorAll(".col-anim").forEach((ps) => {
    const left = ps.querySelector(".ca-left");
    const right = ps.querySelector(".ca-right");

    const tl = gsap.timeline({
      scrollTrigger: {
        markers: debug,
        trigger: ps,
        start: "top bottom-=20%", // trigger viewport
        end: "bottom top", // trigger viewport
        toggleActions: "restart reverse play reverse",
      },
    });

    tl.fromTo(left, { x: "-50%" }, { x: "0%", duration: 1 }, "frame")
      .fromTo(right, { x: "50%" }, { x: "0%", duration: 1 }, "frame")
      .fromTo(
        left,
        { opacity: 0 },
        { opacity: 1, x: "0%", duration: 1 },
        "frame"
      )
      .fromTo(
        right,
        { opacity: 0 },
        { opacity: 1, x: "0%", duration: 1 },
        "frame"
      );

    tls.push(tl);
  });

  document.querySelectorAll(".fade-in").forEach((el) => {
    const tl = gsap
      .timeline({
        scrollTrigger: {
          markers: debug,
          trigger: el,
          start: "top bottom-=20%", // trigger viewport
          end: "bottom top", // trigger viewport
          toggleActions: "restart reverse play reverse",
        },
      })
      .fromTo(el, { opacity: 0 }, { opacity: 1, duration: 0.5 });
    tls.push(tl);
  });

  eventBus.on("scrollTriggerRefresh", () => {
    console.log("scrollTrigger refresh");
    ScrollTrigger.refresh();
  });

  eventBus.on("afterPageChange", () => {
    console.log("after page change", gsap);
    tls.forEach((tl) => tl?.kill?.());
    ScrollTrigger.kill();
  });

  //   const step = {
  //     num: 0,
  //   };
  //   gsap.to(step, {
  //     num: 1000,
  //     onUpdate() {
  //       console.log(step.num);
  //     },
  //     duration: 2,
  //   });
}
