import * as PhotoSwipe from "photoswipe";
import * as PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";

HTMLElement.prototype.addClickEvs = function (sel, fn) {
  this.querySelectorAll(sel).forEach((el) => el.addEventListener("click", fn));
};

export default function Gallery(el) {
  const images = JSON.parse(el.dataset.images);

  addCss();

  function addCss(siteUrl = "/") {
    const link = document.querySelector(".photoswipe");
    if (!link) {
      const el = document.createElement("link");
      el.classList.add("photoswipe");
      el.rel = "stylesheet";
      el.href = siteUrl + "photoswipe.css";
      document.head.appendChild(el);
    }
  }

  const pswpElement = document.querySelectorAll(".pswp")[0];
  const options = {
    // history & focus options are disabled on CodePen
    history: false,
    focus: false,

    showAnimationDuration: 1000,
    hideAnimationDuration: 1000,
  };

  el.addClickEvs("[data-open-gallery]", (e) => {
    e.preventDefault();
    const gallery = new PhotoSwipe(
      pswpElement,
      PhotoSwipeUI_Default,
      images,
      options
    );
    gallery.init();
  });
}
