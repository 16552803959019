// import Plyr from "plyr";
// import Plyr from 'plyr/dist/plyr.polyfilled.js';
import { gsap } from "gsap";

function setVideoSize(el) {
  let ratio = 1;
  const vid = { w: 1920, h: 1080, ratio: 1920 / 1080 };
  const cont = {
    w: el.offsetWidth,
    h: el.offsetHeight,
    ratio: el.offsetWidth / el.offsetHeight,
  };

  if (vid.ratio > cont.ratio) {
    ratio = cont.h / vid.h;
  } else {
    ratio = cont.w / vid.w;
  }

  gsap.set(el.firstElementChild, {
    scale: ratio,
  });
}

export default function BackgroundVideo(el) {
  if (typeof Plyr === "undefined") return;

  const video = el.querySelector("[data-video]");
  let hasStarted = false;
  el.style.opacity = 0;

  const plyr = new Plyr(video, {
    debug: false,
    controls: [],
    autoplay: true,
    muted: true,
    volume: 0,
    // loop: true,
  });

  console.log(plyr);

  plyr.on("ready", () => {
    plyr.volume = 0;
    setVideoSize(el);
  });
  plyr.on("playing", () => {
    if (!hasStarted) {
      gsap.fromTo(el, { opacity: 0 }, { opacity: 1, duration: 1 });
    }
    hasStarted = true;
  });
  plyr.on("timeupdate", () => {
    if (plyr.currentTime >= plyr.duration - 5) {
      plyr.currentTime = 0;
    }
  });

  window.addEventListener("resize", () => setVideoSize(el));
}
