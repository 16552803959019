import { gsap } from "gsap";

export default function VideoPopup(el) {
  console.log("VideoPopup");
  const popup = document.querySelector(".full-video");
  const close = popup.querySelector(".close");
  let player;

  el.addEventListener("click", (e) => {
    const video = popup.querySelector("[data-video]");
    e.preventDefault();
    popup.classList.remove("hidden");
    gsap.fromTo(popup, { opacity: 0 }, { opacity: 1, duration: 0.2 });
    if (typeof Plyr === "undefined") {
      popup.classList.add("hidden");
      return;
    }

    player = new Plyr(video, {
      debug: false,
      // controls: [],
      autoplay: true,
      // muted: true,
      volume: 1,
      // loop: true,
    });
  });

  close.addEventListener("click", (e) => {
    e.preventDefault();
    gsap.fromTo(
      popup,
      { opacity: 1 },
      {
        opacity: 0,
        duration: 0.2,
        onComplete() {
          popup.classList.add("hidden");
          player?.destroy();
        },
      }
    );
  });
}
